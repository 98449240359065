<template>
  <div class="errorBox flex flex-c flex-m">
    <div class="error-content">
      <div class="error-img-l">
        <img src="@/assets/images/pic_empty_404txt@2x.png" />
      </div>
      <div class="error-title">页面丢失…</div>
      <div class="error-text">您访问的页面地址有误或该页面不存在</div>
      <div class="error-btn" @click="$router.push('/home')">返回首页</div>
    </div>
    <div class="error-img-r">
      <img
        src="@/assets/images/pic_empty_404@2x.png"
        alt="海亮教育"
        title="海亮教育"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "not-found",
};
</script>
<style lang="less" scoped>
.errorBox {
  width: 100%;
  height: 100%;
  background: #f6f8fc;
  .error-content {
    padding-left: 75px;
    margin-right: 10px;
  }
  .error-img-l {
    width: 110px;
  }
  .error-title {
    height: 38px;
    font-size: 22px;
    font-weight: 600;
    color: #282828;
    line-height: 38px;
    letter-spacing: 2px;
    margin-top: 15px;
    margin-bottom: 4;
  }
  .error-text {
    font-size: 16px;
    font-weight: 400;
    color: #c2c2d1;
    line-height: 22px;
    margin-bottom: 61px;
  }
  .error-btn {
    width: 120px;
    height: 50px;
    background: @themeBlue;
    box-shadow: 0px 5px 15px 0px rgba(96, 73, 255, 0.14);
    border-radius: 27px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
  .error-img-r {
    width: 758px;
  }
  img {
    display: block;
    max-width: 100%;
  }
}
</style>
